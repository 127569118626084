! function(o, c) {
	var n = c.documentElement, t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);

function clearDefaults() {
	document.getElementById("addressline").value = document.getElementById("postalcode").value;
}

function getConsentCookieValue() {
	var consents = {};

	document.cookie.split(";").some(function(cookie) {
		var key = cookie.split("=")[0].trim();
		var value = cookie.split("=")[1];
		if (key == "gdpr-consent") {
			consents = JSON.parse(decodeURIComponent(value));

			return true;
		}
	});

	return consents;
}

function doesFundraiserUpCookieExist() {
	return document.cookie.split(";").some(function(cookie) {
		var key = cookie.split("=")[0].trim();
		var value = cookie.split("=")[1];
		if (key == "fundraiseup_stat") {
			return true;
		}
	});
}


function getCanonicalHost(hostname) {
	const MAX_TLD_LENGTH = 3;

	function isNotTLD(_) {
		return _.length > MAX_TLD_LENGTH;
	};

	hostname = hostname.split('.');
	hostname = hostname.slice(Math.max(0, hostname.findLastIndex(isNotTLD)));
	hostname = hostname.join('.');

	return hostname;
}

jQuery(document).ready(function($) {
	// Mobile menu
	var $mobileMenuTrigger = $(".w-nav-button");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		offCanvas: {
			position: "left"
		},
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	var API = $mobileMenu.data("mmenu");

	$mobileMenuTrigger.click(function() {
		API.open();
		$mobileMenuTrigger.addClass("w--open");

		return false;
	});

	$mobileMenu.data('mmenu').bind('closed', function() {
		$mobileMenuTrigger.removeClass("w--open");
	});

	/* Google Translate Mobile */
	$(".translate-mobile-button").click(function() {
		$(".google-translation-container-mobile").toggleClass('hide');
		$(".header-contents-wrap").toggleClass("with-translation");
	})

	$(document).click(function(e) {
		// Check if click was triggered on or within #menu_content
		if ($(e.target).closest(".translate-container-mobile").length > 0) {
			return false;
		}

		// Otherwise
		$(".google-translation-container-mobile").addClass("hide");
		$(".header-contents-wrap").removeClass("with-translation");
	});

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 767) {
		$(".main-content-wrap").fitVids({ ignore: "nofit" });
	}

	// "I am a" form
	$("#redirectToForm").submit(function(e) {
		if ($("#redirectToLink").val().length) {
			var website = window.open($("#redirectToLink").val(), '_self');
			website.focus();
		}

		return false;
	});

	// site share
	$("#load-site-share").click(function() {
		$(this).siblings(".nav-drop-list").toggleClass("w--open");

		return false;
	});

	$(".mobile-share-button").click(function() {
		$(this).siblings(".nav-drop-list").toggleClass("w--open");

		return false;
	});

	// mobile search
	$(".mobile-search-button").click(function() {
		$(".mobile-search-form-block").slideToggle();

		return false;
	})

	// main nav drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .nav-drop-toggle > a").click(function(event) {
			if ($(this).closest(".nav-drop-toggle").siblings(".nav-drop-list").hasClass("w--open")) {
				$(".nav-drop-list").removeClass("w--open");
				event.preventDefault();
				$(this).closest(".nav-drop-toggle").siblings(".nav-drop-list").addClass("w--open");
			}
		});
	}
	else {
		$(".has-dropdown").hover(function() {
			$(this).find(".nav-drop-list").addClass("w--open");
		}, function() {
			$(this).find(".nav-drop-list").removeClass("w--open");
		});
	}

	// main nav fly-out menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-flyout > .nav-dropdown-toggle > a").click(function(event) {
			if ($(this).closest(".nav-dropdown-toggle").siblings(".nav-dropdown-list-right").hasClass("w--open")) {
				$(".nav-dropdown-list-right").removeClass("w--open");
				event.preventDefault();
				$(this).closest(".nav-dropdown-toggle").siblings(".nav-dropdown-list-right").addClass("w--open");
			}
		});
	}
	else {
		$(".has-flyout").hover(function() {
			$(this).find(".nav-dropdown-list-right").addClass("w--open");
		}, function() {
			$(this).find(".nav-dropdown-list-right").removeClass("w--open");
		});
	}

	// Remove border from red button
	$('.red-button-with-arrow').parent().css('border-bottom-color', 'transparent');

	/**
	* ----------------------------------------------------------------------
	* Webflow: Interactions: Init
	*/
	var interactions = [];

	interactions = interactions.concat([
		{ slug: "dropdown-hover", name: "Dropdown Hover", value: { style: {}, triggers: [{ type: "hover", stepsA: [{ display: "block" }], stepsB: [{ display: "none" }] }] } },
		{ slug: "new-dropdown", name: "New Dropdown", value: { style: {}, triggers: [{ type: "dropdown", stepsA: [{ opacity: 0, height: "0px" }, { opacity: 1, height: "auto", transition: "opacity 200ms ease 0ms, height 200ms ease 0ms" }], stepsB: [{ height: "0px", transition: "opacity 100ms ease 0ms, height 100ms ease 0ms" }] }] } },
		{ slug: "dropdown-list", name: "Dropdown List ", value: { style: {}, triggers: [{ type: "hover", stepsA: [{ display: "block" }], stepsB: [{ display: "none" }] }] } },
		{ slug: "nav-search-button", name: "Nav Search Button", value: { style: {}, triggers: [{ type: "click", stepsA: [{ display: "block" }], stepsB: [{ display: "none" }] }, { type: "click", stepsA: [{ opacity: 1 }], stepsB: [{ opacity: 0.55 }] }] } },
		{ slug: "md-reveal-doctor-search", name: "MD Reveal Doctor Search", value: { style: {}, triggers: [{ type: "click", selector: ".md-doctor-form-reveal-wrap", stepsA: [{ display: "block", height: "0px" }, { height: "auto", transition: "height 500ms ease 0ms" }], stepsB: [{ height: "0px", transition: "height 500ms ease 0ms" }, { display: "none" }] }] } },
		{ slug: "md-tooltip-button-hover", name: "MD Tooltip Button Hover", value: { style: {}, triggers: [{ type: "hover", selector: ".md-advanced-search-tooltip-popup", descend: true, preserve3d: true, stepsA: [{ display: "block", opacity: 0, x: "0px", y: "1.5rem", z: "0px" }, { wait: 150 }, { opacity: 1, transition: "transform 250ms ease 0ms, opacity 250ms ease 0ms", x: "0px", y: "0px", z: "0px" }], stepsB: [{ wait: 150 }, { opacity: 0, transition: "transform 250ms ease 0ms, opacity 250ms ease 0ms", x: "0px", y: "1.5rem", z: "0px" }, { display: "none", opacity: 1, x: "0px", y: "0px", z: "0px" }] }, { type: "click", selector: ".md-advanced-search-tooltip-popup", descend: true, preserve3d: true, stepsA: [{ display: "block", opacity: 0, x: "0px", y: "1.5rem", z: "0px" }, { wait: 150 }, { opacity: 1, transition: "transform 250ms ease 0ms, opacity 250ms ease 0ms", x: "0px", y: "0px", z: "0px" }], stepsB: [{ opacity: 0, transition: "transform 250ms ease 0ms, opacity 250ms ease 0ms", x: "0px", y: "1.5rem", z: "0px" }, { display: "none", opacity: 1, x: "0px", y: "0px", z: "0px" }] }] } },
		{ slug: "reveal-left-nav", name: "Reveal Left Nav", value: { style: {}, triggers: [{ type: "click", selector: ".left-nav-list-content-wrap", siblings: true, stepsA: [{ height: "auto", transition: "height 500ms ease 0ms" }], stepsB: [{ height: "0px", transition: "height 500ms ease 0ms" }] }] } },
		{ slug: "md-patient-comments-reveal-on-load", name: "MD Patient Comments Reveal (on load)", value: { style: { height: "0px" }, triggers: [] } },
		{ slug: "md-show-more-button", name: "MD Show More Button", value: { style: {}, triggers: [{ type: "click", selector: ".md-patient-comments-reveal-more", stepsA: [{ height: "auto", transition: "height 700ms ease 0ms" }], stepsB: [] }] } },
		{ slug: "loc-reveal-sidebar-search", name: "LOC Reveal Sidebar Search", value: { style: {}, triggers: [{ type: "click", selector: ".loc-sidebar-form-reveal", stepsA: [{ height: "auto", transition: "height 500ms ease 0ms" }], stepsB: [{ height: "0px", transition: "height 500ms ease 0ms" }] }, { type: "click", selector: ".loc-refine-search-button-image", preserve3d: true, stepsA: [{ transition: "transform 500ms ease 0ms", rotateX: "0deg", rotateY: "0deg", rotateZ: "90deg" }], stepsB: [{ transition: "transform 500ms ease 0ms", rotateX: "0deg", rotateY: "0deg", rotateZ: "0deg" }] }] } },
		{ slug: "for-two-part-button-toggle", name: "For Two Part Button Toggle", value: { style: {}, triggers: [{ type: "click", selector: ".two-part-button-list", siblings: true, preserve3d: true, stepsA: [{ transition: "transform 750ms ease 0", x: "-100%", y: "0px", z: "0px" }], stepsB: [] }] } },
		{ slug: "for-two-part-button-list", name: "For Two Part Button List", value: { style: { x: "100%", y: "0px", z: "0px" }, triggers: [] } },
		{ slug: "for-two-part-list-back-button", name: "For Two Part List Back Button", value: { style: {}, triggers: [{ type: "click", selector: ".two-part-button-list", preserve3d: true, stepsA: [{ transition: "transform 750ms ease 0", x: "100%", y: "0px", z: "0px" }], stepsB: [] }] } },
		{ slug: "display-none-on-page-load", name: "Display None On Page Load", value: { style: { display: "none" }, triggers: [] } },
		{ slug: "for-grid-view-button", name: "For Grid View Button", value: { style: { opacity: 0.7500000000000008 }, triggers: [{ type: "click", selector: ".shop-item-row-wrap-list-view", stepsA: [{ display: "none" }], stepsB: [] }, { type: "click", selector: ".shop-item-row-wrap-grid-view", stepsA: [{ display: "flex" }], stepsB: [] }, { type: "click", selector: ".pagination-view-by-button", siblings: true, stepsA: [{ opacity: 0.3500000000000003, transition: "opacity 200ms ease 0ms" }], stepsB: [] }, { type: "click", stepsA: [{ opacity: 0.7500000000000008, transition: "opacity 200ms ease 0ms" }], stepsB: [] }] } },
		{ slug: "for-list-view-button", name: "For List View Button", value: { style: {}, triggers: [{ type: "click", selector: ".shop-item-row-wrap-grid-view", stepsA: [{ display: "none" }], stepsB: [] }, { type: "click", selector: ".shop-item-row-wrap-list-view", stepsA: [{ display: "flex" }], stepsB: [] }, { type: "click", selector: ".pagination-view-by-button", siblings: true, stepsA: [{ opacity: 0.3500000000000003, transition: "opacity 200ms ease 0ms" }], stepsB: [] }, { type: "click", stepsA: [{ opacity: 0.7500000000000008, transition: "opacity 200ms ease 0ms" }], stepsB: [] }] } },
		{ slug: "expand-search-bar", name: "Expand Search Bar", value: { style: {}, triggers: [] } },
		{ slug: "preloader-wrap", name: "Preloader Wrap", value: { style: { display: "flex" }, triggers: [{ type: "load", preload: true, stepsA: [{ wait: 750 }, { opacity: 0, transition: "opacity 750ms ease 0ms" }, { display: "none" }], stepsB: [] }] } },
		{ slug: "mobile-search-reveal", name: "Mobile Search Reveal", value: { style: {}, triggers: [{ type: "click", selector: ".header-mobile-search-reveal", stepsA: [{ height: "0px" }, { height: "auto", transition: "height 500ms ease 0ms" }], stepsB: [{ height: "0px", transition: "height 500ms ease 0ms" }] }, { type: "click", selector: ".mobile-search-close-icon", descend: true, stepsA: [{ display: "block", opacity: 0 }, { opacity: 1, transition: "opacity 300ms ease 0" }], stepsB: [{ display: "none" }] }, { type: "click", selector: ".secondary-nav-icon", descend: true, stepsA: [{ display: "none" }], stepsB: [{ display: "block", opacity: 0 }, { opacity: 0.4, transition: "opacity 300ms ease 0" }] }, { type: "click", selector: ".mobile-search-active-bknd", descend: true, stepsA: [{ display: "block", opacity: 0 }, { opacity: 1, transition: "opacity 300ms ease 0" }], stepsB: [{ opacity: 0, transition: "opacity 300ms ease 0" }, { display: "none" }] }] } },
		{ slug: "hide-mobile-header-on-load", name: "Hide Mobile Header On Load", value: { style: { height: "0px" }, triggers: [] } },
		{ slug: "new-interaction", name: "New Interaction", value: { style: {}, triggers: [] } },
		{ slug: "new-nav-drop-toggle", name: "New Nav Drop Toggle ", value: { style: {}, triggers: [{ type: "dropdown", selector: ".nav-drop-list-new", siblings: true, stepsA: [{ height: "0px" }, { height: "auto", transition: "height 500ms ease 0" }], stepsB: [{ height: "0px", transition: "height 100ms ease 0" }] }] } },
		{ slug: "new-nav-drop-list", name: "New Nav Drop List", value: { style: { height: "0px" }, triggers: [] } },
		{ slug: "nav-drop-wrapper", name: "Nav Drop Wrapper", value: { style: {}, triggers: [{ type: "hover", selector: ".nav-drop-list-element", descend: true, stepsA: [{ display: "block", opacity: 0, height: "0px" }, { opacity: 1, height: "auto", transition: "height 500ms ease 0, opacity 300ms ease 0" }], stepsB: [{ opacity: 0, height: "0px", transition: "height 200 ease 0, opacity 200 ease 0" }, { display: "none" }] }] } },
		{ slug: "nav-drop-list", name: "Nav Drop List", value: { style: {}, triggers: [{ type: "hover", selector: ".nav-drop-background", siblings: true, stepsA: [{ display: "block", opacity: 0 }, { opacity: 1, transition: "opacity 200 ease 0" }], stepsB: [{ opacity: 0, transition: "opacity 200 ease 0" }, { display: "none" }] }] } },
		{ slug: "new-interaction-2search-oggles", name: "New Interaction 2Search oggleS", value: { style: {}, triggers: [] } },
		{ slug: "left-nav-mobile-reveal", name: "Left Nav Mobile Reveal", value: { style: {}, triggers: [{ type: "click", selector: ".left-nav-mobile-reveal", siblings: true, stepsA: [{ height: "auto", transition: "height 500ms ease 0" }], stepsB: [{ height: "0px", transition: "height 500ms ease 0" }] }, { type: "click", selector: ".left-nav-reveal-button-icon", descend: true, preserve3d: true, stepsA: [{ transition: "transform 500ms ease 0", rotateX: "0deg", rotateY: "0deg", rotateZ: "180deg" }], stepsB: [{ transition: "transform 500ms ease 0", rotateX: "0deg", rotateY: "0deg", rotateZ: "0deg" }] }] } },
		{ slug: "search-toggle", name: "Search Toggle", value: { style: {}, triggers: [{ type: "click", selector: ".mobile-search-dd-wrap", stepsA: [{ display: "block", height: "auto" }], stepsB: [{ display: "none", height: "0px", transition: "height 200 ease 0" }] }] } },
		{ slug: "news-feed-hover", name: "News Feed Hover", value: { style: {}, triggers: [{ type: "hover", selector: ".news-image", descend: true, stepsA: [{ opacity: 0.8, transition: "opacity 250ms ease 0" }], stepsB: [{ opacity: 1, transition: "opacity 250ms ease 0" }] }] } }
	]);

	Webflow.require('ix').init(interactions);

	// Delete Fundraiser stats cookie if no consent was given.
	deleteFundraiserUpCookie = () => {
		if ( doesFundraiserUpCookieExist() ) {
			document.cookie = "fundraiseup_stat=;Domain=." + getCanonicalHost(window.location.hostname) + "; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
			clearInterval(cookieInterval);
		}
	}
	let consent = getConsentCookieValue();
	if (Object.keys(consent).length === 0 || consent.fundraiserUp === undefined || consent.fundraiserUp === false) {
		cookieInterval = setInterval(deleteFundraiserUpCookie, 500);
	}
});
